<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <div slot="list" slot-scope="data">
      <b-card style="margin-bottom: 0">
        <b-table
          :fields="table.fields"
          :items="items"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          no-local-sorting
          responsive
        >
          <template slot="visible" slot-scope="data">
            <c-switch
              type="default"
              size="xs"
              class="center-block align-middle mb-0"
              :checked="!!data.value"
              @change="changeVisible(data.item, $event)"
            ></c-switch>
          </template>

          <template slot="id" slot-scope="data">
            <span :id="'id-row_' + data.index" :to="'/projects/' + data.item.id">{{ data.value }}</span>
            <popover :target="'id-row_' + data.index">
              <div>
                <b>HID: </b>
                <span>{{ data.item.extra.asana_hook_id || '-' }}</span>
              </div>
              <div>
                <b>TD: </b>
                <a v-if="data.item.extra.td_id" :href="data.item.tdLink" target="_blank">{{
                  data.item.extra.td_id || 'перейти'
                }}</a>
                <template v-else>-</template>
              </div>
            </popover>
          </template>

          <template slot="name" slot-scope="data">
            <fa icon="pencil-alt" size="sm" class="mx-10 edit-icon" @click="modalSwitch(data.item)" />
            <b-link :id="'name-row_' + data.index" :to="'/projects/' + data.item.id"
              >{{ data.value | slicer(30) }}
            </b-link>
            <template v-if="positions.indexOf('admin') >= 0"> </template>
            <popover :target="'name-row_' + data.index">
              <div>
                <div v-if="getLinkByAlias(data.item._links, 'amoCRM')">
                  <b>Сделка: </b>
                  <a :href="getLinkByAlias(data.item._links, 'amoCRM')" target="_blank">{{
                    data.value || 'перейти'
                  }}</a>
                </div>
                <div v-if="data.item.client">
                  <b>Компания: </b>
                  <a
                    :href="'https://rocketcrm.amocrm.ru/companies/detail/' + data.item.client.extra.amocrm_company_id"
                    target="_blank"
                    >{{ data.item.client.name }}</a
                  >
                </div>
                <div v-if="getLinkByAlias(data.item._links, 'Asana')">
                  <b>Asana: </b>
                  <a :href="getLinkByAlias(data.item._links, 'Asana')" target="_blank">перейти</a>
                </div>
                <div v-if="getLinkByAlias(data.item._links, 'Google Drive')">
                  <b>Папка в Google Drive: </b>
                  <a :href="getLinkByAlias(data.item._links, 'Google Drive')" target="_blank">перейти</a>
                </div>
                <div v-if="getLinkByAlias(data.item._links, 'B2B')">
                  <b>B2B: </b>
                  <a :href="getLinkByAlias(data.item._links, 'B2B')" target="_blank">перейти</a>
                </div>
              </div>
            </popover>
          </template>

          <template slot="responsible" slot-scope="data">
            <responsible :responsible="data.value"></responsible>
          </template>

          <template slot="followers" slot-scope="data">
            <followers :item="data.item"></followers>
          </template>

          <template slot="averagePrice" slot-scope="data">
            {{ (data.item.tasks && tasksAveragePrice(data.item.tasks)) || 0 }} &#8381;
          </template>

          <template slot="clientName" slot-scope="data">
            <template v-if="data.item.client && data.item.client.person && data.item.client.person.fields">
              <template v-if="data.item.client._links.amocrm_company_link">
                <a :href="data.item.client._links.amocrm_company_link.href" target="_blank">{{
                  (data.item.client.person.fields.name || 'Перейти') | slicer(30)
                }}</a>
              </template>
              <template v-else>{{ data.item.client.person.fields.name || 'Перейти' }}</template>
            </template>
            <template v-else>-</template>
          </template>

          <template slot="asana_task_id" slot-scope="data">
            <template v-if="data.item.extra.asana_task_id">
              <a :href="'https://app.asana.com/0/' + data.item.extra.asana_task_id + '/list'" target="_blank"
                >Перейти</a
              >
            </template>
            <template v-else>-</template>
          </template>

          <template slot="td_id" slot-scope="data">
            <a v-if="data.item.extra.td_id" :href="data.item.tdLink || '#'">{{ data.item.extra.td_id }}</a>
            <template v-else>-</template>
          </template>

          <template slot="amocrm_company_id" slot-scope="data">
            <a
              v-if="data.item.extra.amocrm_company_id"
              :href="'https://rocketcrm.amocrm.ru/companies/detail/' + data.item.extra.amocrm_company_id"
              target="_blank"
              >Перейти</a
            >
            <template v-else>-</template>
          </template>

          <template slot="asana_hook_id" slot-scope="data">
            {{ data.item.extra.asana_hook_id || '-' }}
          </template>

          <template slot="amocrm_id" slot-scope="data">
            <template v-if="data.item.extra.amocrm_lead_id">
              <a :href="'https://rocketcrm.amocrm.ru/leads/detail/' + data.item.extra.amocrm_lead_id" target="_blank">{{
                data.item.extra.amocrm_lead_id
              }}</a>
            </template>
            <template v-else>-</template>
          </template>

          <template slot="created_at" slot-scope="data">
            <template v-if="data.value">
              {{ data.value | formatDate('DD.MM.YYYY') }}
            </template>
            <template v-else>-</template>
          </template>

          <template slot="closed_at" slot-scope="data">
            <template v-if="data.value">
              {{ data.value | formatDate('DD.MM.YYYY') }}
            </template>
            <template v-else>-</template>
          </template>

          <template slot="leadStatus" slot-scope="data">
            <lead-status :item="data.item" />
          </template>

          <template slot="tasksCount" slot-scope="data">
            <b-link
              :to="'/tasks?filters[tasks][project_id]=' + data.item.id + '&filters[tasks][preset]=allOpenedAndClosed'"
            >
              {{ data.value }}
            </b-link>
          </template>

          <template slot="price" slot-scope="data">
            <budget :item="data.item" />
          </template>

          <template slot="payments_duty" slot-scope="data">
            <payments-duty :item="data.item" />
          </template>

          <template slot="cost" slot-scope="data">
            <span :id="'cost-row_' + data.index">
              <progress-bar :value="data.item.cost_percent || 0" reverse :max="150">
                <span>{{ data.value | formatNumber }} &#8381; ({{ data.item.cost_percent || 0 | formatNumber }}%)</span>
              </progress-bar>
            </span>
            <popover :target="'cost-row_' + data.index">
              <div>
                <div v-if="data.item.employee_statistic && data.item.employee_statistic.length">
                  <ul class="pl-15 m-0">
                    <li v-for="employee in data.item.employee_statistic" class="mt-2">
                      {{ employee.name }}<br />
                      {{ employee.seconds | formatDuration }} * {{ employee.cost_per_hour | formatNumber }} =
                      {{ employee.cost | formatNumber }} &#8381;
                    </li>
                  </ul>
                  <br />
                </div>
                <div>
                  <b>Средняя стоимость часа (для клиента): </b><br />
                  Бюджет / Часы = Ср. стоимость в час <br />
                  {{ data.item.price | formatNumber }} &#8381; /
                  {{ data.item.employee_statistic_total | formatDuration }} =
                  {{ data.item.average_hour_cost || 0 | formatNumber }} &#8381;
                </div>
                <div class="mt-5">
                  <b>Остаток бюджета: {{ data.item.free_budget | formatNumber }} &#8381;</b>
                </div>
              </div>
            </popover>
          </template>

          <template slot="free_budget" slot-scope="data">
            <span :id="'free_budget-row_' + data.index"
              >{{ data.value | formatNumber }} &#8381; ({{
                data.item.free_budget_percent || 0 | formatNumber('0,[00]')
              }}%)</span
            >
            <popover :target="'free_budget-row_' + data.index">
              <div>
                <div>
                  <b>Примерный остаток часов: </b><br />
                  <span>Остаток бюджета / ср. стоимость часа</span><br />
                  <span
                    >{{ data.value | formatNumber }} &#8381; /
                    {{ data.item.average_hour_cost || 0 | formatNumber }} &#8381; =
                  </span>
                  <span>{{ data.item.reminder_hours || 0 | formatDuration(undefined, undefined, 'hours') }}</span>
                </div>
              </div>
            </popover>
          </template>

          <template slot="employee_statistic_total" slot-scope="data">
            <hours :item="data.item" />
          </template>

          <template slot="worth" slot-scope="data">
            <span :id="'worth-row_' + data.index"
              >{{ data.value | formatNumber }} &#8381; ({{ data.item.worth_percent || 0 | formatNumber }}%)</span
            >
            <popover :target="'worth-row_' + data.index">
              <div>
                <div v-if="data.item.employee_statistic && data.item.employee_statistic.length">
                  <ul class="pl-15 m-0">
                    <li v-for="employee in data.item.employee_statistic" class="mt-2">
                      {{ employee.name }}<br />
                      {{ employee.seconds | formatDuration }} * {{ employee.worth_per_hour | formatNumber }} =
                      {{ employee.worth | formatNumber }} &#8381;
                    </li>
                  </ul>
                  <br />
                </div>
                <div>
                  <b>Средняя себестоимость работ: </b><br />
                  Себестоимость / Часы = Ср. в час<br />
                  {{ data.value | formatNumber }} &#8381; / {{ data.item.employee_statistic_total | formatDuration }} =
                  {{ data.item.worth_average | formatNumber }} &#8381;
                </div>
                <div v-if="$dotGet(data.item, 'expenditureOperations', []).length">
                  <br />
                  <b>Расходы: </b><br />
                  <ul class="pl-15 m-0">
                    <li v-for="operation in data.item.expenditureOperations" class="mt-1">
                      {{ operation.amount.slice(1) | formatNumber }} &#8381; {{ operation.comment }} ({{
                        operation.created_at | formatDate
                      }})
                    </li>
                  </ul>
                </div>
              </div>
            </popover>
          </template>

          <template slot="margin" slot-scope="data">
            <span :id="'margin-row_' + data.index"
              >{{ data.value | formatNumber }} &#8381; ({{
                data.item.margin_percent || 0 | formatNumber('0,[00]')
              }}%)</span
            >
            <popover :target="'margin-row_' + data.index">
              <div>
                <div>
                  <b>Вал. прибыль по оплатам: </b>
                  {{ data.item.margin_payment | formatNumber }} &#8381; ({{
                    data.item.margin_payment_percent || 0 | formatNumber('0,[00]')
                  }}%)
                </div>
                <div>
                  <b>Средняя маржа в час: </b>
                  {{ data.item.margin_per_hour | formatNumber }} &#8381;
                </div>
              </div>
            </popover>
          </template>

          <template slot="days_to_deadline" slot-scope="data">
            <deadline :item="data.item" />
          </template>

          <div slot="remarks" slot-scope="data" style="display: flex">
            <div
              v-if="data.value && data.value.length"
              v-for="remark in data.value"
              class="mr-3"
              :title="remark.name"
              v-b-tooltip.hover
              style="width: 10px; height: 10px; border-radius: 50%"
              :style="{ background: remark.color }"
            ></div>
          </div>

          <template slot="efficiency" slot-scope="data">
            <span :id="'efficiency-row_' + data.index"> {{ data.value || 0 | formatNumber }}% </span>
            <popover :target="'efficiency-row_' + data.index">
              <div>
                <b>Наценка: </b>
                {{ data.item.markup_percent || 0 | formatNumber }}%
              </div>
              <div>
                <b>Дней в работе: </b>
                {{ data.item.days_in_work || 0 | formatNumber }}
              </div>
            </popover>
          </template>

          <template slot="manager_bonus_total" slot-scope="data">
            <span :id="'manager_bonus_total-row_' + data.index">
              {{ data.value || 0 | formatNumber }} &#8381; ({{
                data.item.manager_bonus_total_percent || 0 | formatNumber
              }}%)
            </span>
            <popover :target="'manager_bonus_total-row_' + data.index">
              <div>
                <div>
                  <b>Расход часов: </b>
                  <template>
                    {{ data.item.manager_bonus_hours_fact | formatDuration(undefined, undefined, 'hours') }} /
                    {{ data.item.manager_bonus_hours_plan | formatDuration(undefined, undefined, 'hours') }}
                    ({{ data.item.manager_bonus_hours_mastering_percent | formatNumber }}%)
                  </template>
                </div>

                <!--<div v-if="data.item.manager_bonus_hours_saving_percent" class="green">-->
                <!--<b>Экономия: </b>-->
                <!--<template>-->
                <!--{{data.item.manager_bonus_hours_saving_percent | formatNumber}}%-->
                <!--</template>-->
                <!--</div>-->

                <div v-if="data.item.manager_bonus_hours_overdue_percent" class="red">
                  <b>Превышение: </b>
                  <template> {{ data.item.manager_bonus_hours_overdue_percent | formatNumber }}% </template>
                </div>

                <div v-if="data.item.manager_bonus_date_penalty_percent" class="red">
                  <b>Просрочка: </b>
                  {{ data.item.manager_bonus_overdue_days }}д * {{ data.item.manager_bonus_date_penalty_coeff }}% =
                  {{ data.item.manager_bonus_date_penalty_percent | formatNumber }}% (не более
                  {{ data.item.manager_bonus_date_max_penalty_percent }}%)
                </div>
                <div>
                  <b>Бонус менеджера: </b>
                  <template> {{ data.item.manager_bonus_percent | formatNumber }}% </template>
                </div>
                <div>
                  <b>Маржинальная отгрузка: </b>
                  <template> {{ data.item.margin_shipment | formatNumber }} &#8381; </template>
                </div>
                <div>
                  <b>Коэффициент: </b>
                  (
                  {{ data.item.manager_bonus_gainful_percent }}%
                  <!--<span v-if="data.item.manager_bonus_hours_saving_percent" class="green">-->
                  <!--+ {{data.item.manager_bonus_hours_saving_percent | formatNumber}}%-->
                  <!--</span>-->
                  <span v-if="data.item.manager_bonus_hours_overdue_percent_calc" class="red">
                    - {{ data.item.manager_bonus_hours_overdue_percent_calc | formatNumber }}%
                  </span>
                  <span v-if="data.item.manager_bonus_date_penalty_percent" class="red">
                    - {{ data.item.manager_bonus_date_penalty_percent | formatNumber }}%
                  </span>
                  ) / 100 =
                  <span :class="{ red: data.item.manager_bonus_coeff <= 1, green: data.item.manager_bonus_coeff > 1 }">
                    {{ data.item.manager_bonus_coeff | formatNumber('0,0.[00]') }}
                  </span>
                </div>
                <div>
                  <b>Премия: </b>
                  <template>
                    ({{ data.item.manager_bonus_percent | formatNumber }}% от
                    {{ data.item.margin_shipment | formatNumber }}) *
                    {{ data.item.manager_bonus_coeff | formatNumber('0,0.[00]') }} =
                    {{ data.item.manager_bonus_total | formatNumber }} &#8381;
                  </template>
                </div>

                <template>
                  <br />
                  <ul class="pl-15 m-0">
                    <!--                    <li-->
                    <!--                      v-for="employee in [-->
                    <!--                        ...data.item.manager_bonus_employee_statistic,-->
                    <!--                        ...Object.assign({}, data.item.manager_bonus_total_statistic, { _all: true }),-->
                    <!--                      ]"-->
                    <!--                      class="mt-2"-->
                    <!--                    >-->
                    <!--                      <b>{{ employee._all ? 'Все вместе' : employee.name }}</b-->
                    <!--                      ><br />-->
                    <!--                      {{ employee.hours | formatDuration(undefined, undefined, 'hours') }}-->
                    <!--                      <template v-if="employee.premium !== null">-->
                    <!--                        * {{ employee.premium | formatNumber }}&#8381;-->
                    <!--                      </template>-->
                    <!--                      *-->
                    <!--                      <span :class="{ red: employee.premium_coeff <= 1, green: employee.premium_coeff > 1 }">-->
                    <!--                        {{ employee.premium_coeff | formatNumber('0,0.[00]') }}-->
                    <!--                      </span>-->
                    <!--                      <template v-if="employee.total !== null"> = {{ employee.total | formatNumber }}&#8381; </template>-->
                    <!--                    </li>-->
                  </ul>
                </template>
              </div>
            </popover>
          </template>

          <template slot="nps" slot-scope="data">
            {{ data.value || '-' }}
          </template>

          <template slot="is_tasks_billable" slot-scope="data">
            <template v-if="data.value">
              <div :id="'is_tasks_billable-row_' + data.index">
                <fa icon="dollar-sign" size="sm" class="text-success" />
                <span style="font-weight: 500; vertical-align: -1px">
                  {{ data.item.bonus_coeff | formatNumber('0,0.0[0]') }}
                </span>
              </div>
              <b-tooltip :target="'is_tasks_billable-row_' + data.index" boundary="viewport">
                Премиальный проект
              </b-tooltip>
            </template>
            <template v-else class="text-secondary">-</template>
          </template>

          <!--Прямые расходы-->
          <template slot="actual_direct_expenses_total" slot-scope="{ item }">
            <direct-expenses :item="item" />
          </template>

          <template slot="margin_shipment" slot-scope="{ item }">
            <margin-shipment :item="item" />
          </template>

          <th-extended slot="HEAD_is_tasks_billable" slot-scope="data" :data="data" :fields="table.fields">
            <fa icon="dollar-sign" size="sm"></fa>
          </th-extended>

          <!--Шапка себестоимости-->
          <th-extended slot="HEAD_worth" slot-scope="data" :data="data">
            <template slot="popover"> Себестоимость времени без прямых расходов </template>
          </th-extended>

          <!--Шапка прямых расходов-->
          <th-extended slot="HEAD_actual_direct_expenses_total" slot-scope="data" :data="data">
            <template slot="popover"> Прямые расходы из amoCRM </template>
          </th-extended>
        </b-table>
        <edit-project :source="modal.item" :module="module"></edit-project>
      </b-card>

      <Summary :filters="module.getters.query" :count="items.length"></Summary>
    </div>
  </list>
</template>

<script>
  import api from '../../assets/js/api'
  import { getUsersDropdown } from '../../assets/js/common'

  import { mapState } from 'vuex'

  import entityMixins from '../../mixins/entity'

  import Summary from './Summary'
  import List from '../../components/List'
  import Popover from '../../components/PopoverAlive'
  import Followers from './Followers'
  import Responsible from '../../components/Responsible'
  import EditProject from './EditProject'
  import cSwitch from '../../components/Switch'
  import ProgressBar from '../../components/ProgressBar'
  import ThExtended from '../../components/ThExtended'

  import LeadStatus from './common/LeadStatus'
  import Budget from './common/Budget'
  import PaymentsDuty from './common/PaymentsDuty'
  import Hours from './common/Hours'
  import Deadline from './common/Deadline'
  import DirectExpenses from './common/DirectExpenses'
  import MarginShipment from './common/MarginShipment'

  export default {
    name: 'ProjectsList',
    components: {
      Summary,
      List,
      Popover,
      Followers,
      Responsible,
      EditProject,
      cSwitch,
      ProgressBar,
      ThExtended,
      LeadStatus,
      Budget,
      PaymentsDuty,
      Hours,
      Deadline,
      DirectExpenses,
      MarginShipment,
    },
    mixins: [entityMixins],
    data() {
      return {
        table: {
          fields: [
            {
              key: 'visible',
              label: 'Актив.',
              class: 'text-center ',
              roles: ['admin'],
            },
            {
              key: 'id',
              label: 'ID',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'name',
              label: 'Название',
              sortable: true,
              class: '',
            },
            {
              key: 'is_tasks_billable',
              label: 'Премиальность',
              class: 'text-center',
            },
            {
              key: 'tasksCount',
              label: 'Задачи',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'leadStatus',
              label: 'Статус сделки',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'leadType',
              label: 'Тип сделки',
              sortable: true,
            },
            {
              key: 'followers',
              label: 'Участники',
            },
            // {
            //   key: 'remarks',
            //   label: 'Замечания',
            //   class: 'align-middle'
            // },
            {
              key: 'price',
              label: 'Бюджет',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'manager_bonus_total',
              label: 'Премия',
              sortable: true,
              class: 'text-center',
            },
            // {
            //   key: 'payments_duty',
            //   label: 'Долг',
            //   sortable: true,
            //   class: 'text-center',
            //   roles: ['admin'],
            // },
            {
              key: 'employee_statistic_total',
              label: 'Время',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'days_to_deadline',
              label: 'Сроки',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'nps',
              label: 'NPS',
              class: 'text-center',
              sortable: true,
            },
            // {
            //   key: 'free_budget',
            //   label: 'Остаток бюджета',
            //   sortable: true,
            //   class: 'text-center',
            //   roles: ['admin'],
            // },
            {
              key: 'actual_direct_expenses_total',
              label: 'Прямые расходы',
              class: 'text-center',
              sortable: true,
              roles: ['admin'],
            },
            {
              key: 'margin_shipment',
              label: 'Марж. отгрузка',
              class: 'text-center',
              sortable: true,
              roles: ['admin'],
            },
            {
              key: 'worth',
              label: 'Себестоимость',
              class: 'text-center',
              sortable: true,
              roles: ['admin'],
            },
            {
              key: 'margin',
              label: 'Валовая прибыль',
              class: 'text-center',
              sortable: true,
              roles: ['admin'],
            },
            {
              key: 'cost',
              label: 'Освоение бюджета',
              sortable: true,
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'efficiency',
              label: 'Эффективность',
              class: 'text-center',
              sortable: true,
              roles: ['admin'],
            },
          ],
        },
        filters: [
          {
            label: 'Название',
            name: 'name',
            default: 'erp',
            width: 2,
          },
          {
            label: 'Клиент',
            name: 'company_query',
            width: 2,
          },
          {
            label: 'Менеджер проекта',
            name: 'project_manager_id',
            type: 'select',
            items: [],
            width: 2,
            multiple: true,
          },
          {
            label: 'Менеджер продаж',
            name: 'sale_manager_id',
            type: 'select',
            items: [],
            width: 2,
            multiple: true,
          },
          {
            label: 'Тип сделки',
            name: 'lead_type_id',
            type: 'select',
            items: [],
            width: 2,
            multiple: true,
          },
          {
            label: 'Статус сделки',
            name: 'lead_status_id',
            type: 'select',
            items: [],
            width: 2,
            multiple: true,
          },
          {
            name: 'project_start',
            type: 'date',
            range: true,
            legend: 'Пр. старт',
            width: 3,
          },
          {
            name: 'deadline',
            type: 'date',
            range: true,
            legend: 'Закр. план',
            width: 3,
          },
          {
            name: 'project_end',
            type: 'date',
            range: true,
            legend: 'Закр. факт',
            width: 3,
          },
          {
            label: 'Пресеты',
            name: 'preset',
            type: 'select',
            items: [
              {
                name: 'Все активные',
                value: 'allActive',
                description: 'Без архивных',
              },
              {
                name: 'Все активные и все архивные',
                value: 'allActiveAndArchive',
                description: 'С архивными',
              },
              {
                name: 'Все архивные',
                value: 'allArchive',
                description: 'Только архивные',
              },
              {
                name: 'Премиальные',
                value: 'premium',
                description: 'Только премиальные',
              },
              {
                name: 'Все сделки в работе',
                value: 'allInWork',
                description: 'Есть дата начала, нет даты закрытия, тип сделки: все',
              },
              {
                name: 'Внедрение в работе',
                value: 'introductionInWork',
                description:
                  'Есть дата начала, нет даты закрытия, тип сделки: работы, работы почасовка, консалтинг, сопровождение',
              },
              {
                name: 'Разработка в работе',
                value: 'developmentInWork',
                description: 'Есть дата начала, нет даты закрытия, тип сделки: разработка, разработка почасовка',
              },
              {
                name: 'Лицензии в работе',
                value: 'licensesInWork',
                description: 'Есть дата начала, нет даты закрытия, тип сделки: лицензии',
              },
              {
                name: 'Внутренние проекты',
                value: 'insideProjects',
                description: 'Нет ссылки на сделку amoCRM',
              },
              {
                name: 'Дебиторка',
                value: 'debit',
                description: 'Закрытые, но не оплаченные проекты (сумма долга)',
              },
              {
                name: 'Кредиторка',
                value: 'credit',
                description: 'Не закрытые, оплаченные проекты (бюджеты)',
              },
              {
                name: 'Потенциальные доплаты',
                value: 'potentialPayments',
                description: 'Не закрытые и закрытые, но не оплаченные проекты (сумма долга)',
              },
              {
                name: 'Косячные',
                value: 'mowing',
                description: 'Закрытые, но оплаты меньше чем бюджет',
              },
            ],
            width: 3,
          },
        ],
      }
    },
    computed: {
      ...mapState({
        positions: state => state.auth.user.positions,
      }),
    },
    methods: {
      getLinkByAlias(links, name) {
        let find =
          links &&
          links.find(link => {
            return link.name === name
          })

        return (find && find.link) || null
      },
      tasksWithPrice(tasks) {
        return tasks.reduce(function (accumulator, value, index, array) {
          return accumulator + (value.price ? 1 : 0)
        }, 0)
      },
      tasksPrice(tasks) {
        return tasks.reduce(function (accumulator, value, index, array) {
          return accumulator + (parseInt(value.price) || 0)
        }, 0)
      },
      tasksAveragePrice(tasks) {
        let count = this.tasksWithPrice(tasks)

        return count ? parseInt(this.tasksPrice(tasks) / count) : 0
      },
    },
    created() {
      let vm = this

      this.$store.commit('currentView/setActions', [
        {
          label: 'Создать',
          icon: 'plus-square',
          onClick() {
            vm.modalSwitch(null)
          },
        },
      ])
    },
    beforeRouteEnter(to, from, next) {
      Promise.all([
        api.base.get({
          path: 'fields/2',
        }),
        getUsersDropdown(),
      ]).then(([fields, items]) => {
        next(vm => {
          let filters = vm.filters

          if (filters) {
            fields.fields.forEach(field => {
              if (field.alias === 'lead_type') {
                filters.find(filter => {
                  return filter.name === 'lead_type_id'
                }).items = field.enums.map(fieldEnum => {
                  return {
                    value: fieldEnum.id,
                    name: fieldEnum.value,
                  }
                })
              } else if (field.alias === 'lead_status') {
                let pipelines = {}

                field.enums.forEach(fieldEnum => {
                  let pipelineId = fieldEnum.extra && fieldEnum.extra['amo_pipeline_id']
                  if (pipelineId) {
                    pipelines[pipelineId] = pipelines[pipelineId] || {
                      name: fieldEnum.extra['amo_pipeline_name'],
                      items: [],
                    }
                    pipelines[pipelineId].items.push({
                      name: fieldEnum.value,
                      value: fieldEnum.id,
                    })
                  }
                })

                filters.find(filter => {
                  return filter.name === 'lead_status_id'
                }).items = Object.values(pipelines)
              }
            })

            filters.find(filter => {
              return filter.name === 'project_manager_id'
            }).items = items.slice()

            filters.find(filter => {
              return filter.name === 'sale_manager_id'
            }).items = items.slice()
          }
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    table {
      white-space: nowrap;
    }

    .card-body {
      padding-bottom: 0;
    }
  }
</style>
