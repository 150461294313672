<template>
  <div>
    <template v-if="item['actual_direct_expenses_total']">
      <span :id="id" :class="{ red: hasExpired }"> {{ item['direct_expenses_total'] | formatNumber }} &#8381; </span>

      <popover :target="id">
        <div>
          <b>Фактические расходы:</b>
          <ol v-if="(item['actual_direct_expenses'] || []).length" class="pl-15 m-0">
            <li v-for="expense in item['actual_direct_expenses']">
              {{ Math.abs(expense.amount) | formatNumber }} &#8381; ({{ expense.date | formatDate }})
              <template v-if="expense.name">
                <br />
                <span class="text-secondary">
                  {{ expense.name }}
                </span>
              </template>
            </li>
          </ol>
          <template v-else>-</template>
        </div>
        <br />
        <div>
          <b>Плановые расходы:</b>
          <ol v-if="(item['future_direct_expenses'] || []).length" class="pl-15 m-0">
            <li v-for="expense in item['future_direct_expenses']" :class="{ red: expired(expense) }">
              {{ Math.abs(expense.amount) | formatNumber }} &#8381; ({{ expense.date | formatDate }})
              <template v-if="expense.name">
                <br />
                <span class="text-secondary">
                  {{ expense.name }}
                </span>
              </template>
            </li>
          </ol>
          <template v-else>-</template>
        </div>
      </popover>
    </template>
    <template v-else> - </template>
  </div>
</template>

<script>
  import uniqid from 'uniqid'

  import Popover from '../../../components/PopoverAlive'

  export default {
    name: 'ActualDirectExpenses',
    props: ['item'],
    components: {
      Popover,
    },
    data() {
      return {
        id: uniqid('budget-'),
      }
    },
    computed: {
      hasExpired() {
        return this.item['future_direct_expenses'].some(p => {
          return this.expired(p)
        })
      },
    },
    methods: {
      expired(expense) {
        return Date.now() > Date.parse(expense.date)
      },
    },
  }
</script>

<style scoped></style>
