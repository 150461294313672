<template>
  <div>
    {{ item['margin_shipment'] | formatNumber }} &#8381; ({{ item['margin_shipment_percent'] | formatNumber }}%)
  </div>
</template>

<script>
  export default {
    name: 'MarginShipment',
    props: ['item'],
  }
</script>

<style scoped></style>
