<template>
  <div v-if="withRoles && withRoles.length" class="project-followers">
    <template v-for="(follower, index) in withRoles" v-if="index < limit">
      <img
        :id="item.id + '_' + follower.id"
        :src="follower.photoLink || follower.avatar || require('static/img/avatars/default.jpg')"
        class="user-avatar"
        @error="onError($event)"
      />

      <b-tooltip :target="item.id + '_' + follower.id" boundary="viewport">
        <div class="text-left">
          <span>{{ follower.name }}</span>
          <ul class="pl-15 m-0">
            <li v-for="role in follower.localRoles || ['Участник']">{{ role }}</li>
          </ul>
        </div>
      </b-tooltip>
    </template>

    <template v-if="withRoles.length > limit">
      <div style="height: 100%" :id="'more-followers-' + item.id">
        <fa class="ml-1" icon="angle-right" size="sm"></fa>
      </div>
      <popover :target="'more-followers-' + item.id" placement="right">
        <div class="project-followers">
          <template v-for="(follower, index) in withRoles" v-if="index >= limit">
            <span>
              <img
                :src="follower.photoLink || follower.avatar || require('static/img/avatars/default.jpg')"
                :title="follower.name"
                class="user-avatar"
                @error="onError($event)"
              />{{ follower.name }}
            </span>
          </template>
        </div>
      </popover>
    </template>
  </div>
</template>

<script>
  import defaultAvatar from 'static/img/avatars/default.jpg'
  import cloneDeep from 'lodash/cloneDeep'

  import Popover from '../../components/PopoverAlive'

  export default {
    name: 'followers',
    components: {
      Popover,
    },
    props: {
      item: Object,
      limit: {
        type: Number,
        default: 6,
      },
    },
    data() {
      return {
        roles: [
          {
            alias: 'responsible',
            name: 'Ответственный',
            weight: 4,
          },
          {
            alias: 'saleManager',
            name: 'Менеджер по продажам',
            weight: 3,
          },
          {
            alias: 'projectManager',
            name: 'Менеджер проекта',
            weight: 2,
          },
          {
            alias: 'tuner',
            name: 'Настройщик',
            weight: 1,
          },
        ],
      }
    },
    computed: {
      followers() {
        return (this.item && this.item.followers) || []
      },
      withRoles() {
        let all = cloneDeep(this.followers)

        this.roles.forEach(role => {
          if (this.item && this.item[role.alias]) {
            let withRole = this.item[role.alias]

            let find = all.find(item => {
              return item.id === withRole.id
            })

            if (find) {
              Object.assign(find, withRole)

              find.localRoles = find.localRoles || []
              find.localRoles.push(role.name)

              find.localWeight = find.localWeight || 0
              find.localWeight += role.weight
            } else {
              withRole.localRoles = [role.name]
              withRole.localWeight = role.weight
              all.push(withRole)
            }
          }
        })

        all.sort((a, b) => {
          a.localWeight = a.localWeight || 0
          b.localWeight = b.localWeight || 0
          if (a.localWeight > b.localWeight) {
            return -1
          }
          if (a.localWeight < b.localWeight) {
            return 1
          }
          return 0
        })

        return all
      },
    },
    methods: {
      onError(e) {
        e.target.src = defaultAvatar
      },
    },
  }
</script>

<style lang="scss">
  .project-followers {
    display: flex;
  }

  .popover {
    .project-followers {
      display: grid;

      & > span {
        margin-bottom: 5px;

        img {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
</style>
