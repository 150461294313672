<template>
  <b-card class="panel" :class="{ 'is-loading': loading }">
    <b-table :fields="table.fields" :items="[data]" class="m-0">
      <template slot="count" slot-scope="data"> Отображено {{ count }} из {{ data.value || 0 }} </template>

      <template slot="budget" slot-scope="data">
        <span id="total_budget"
          >{{ data.value || 0 | formatNumber }} &#8381; ({{ data.item.budget_percent || 0 | formatNumber }}%)</span
        >
        <popover v-if="mergedEmployees" target="total_budget">
          Средняя стоимость часа (план) = {{ data.item.average_cost_plan_hours | formatNumber }} &#8381;<br />
          (Суммарный бюджет / все плановые часы)<br />
          <br />
          Средняя стоимость часа (факт) = {{ data.item.average_cost_fact_hours | formatNumber }} &#8381;<br />
          (Суммарный бюджет / все фактические часы)
        </popover>
      </template>

      <template slot="payments" slot-scope="data">
        {{ data.value || 0 | formatNumber }} &#8381; ({{ data.item.payments_percent || 0 | formatNumber }}%)
      </template>

      <template slot="duty" slot-scope="data">
        {{ data.value || 0 | formatNumber }} &#8381; ({{ data.item.duty_percent || 0 | formatNumber }}%)
      </template>

      <template slot="free_budget" slot-scope="data">
        {{ data.value || 0 | formatNumber }} &#8381; ({{ data.item.free_budget_percent || 0 | formatNumber }}%)
      </template>

      <template slot="worth" slot-scope="data">
        <span id="total_worth"
          >{{ data.value || 0 | formatNumber }} &#8381; ({{ data.item.worth_percent || 0 | formatNumber }}%)</span
        >
        <popover v-if="mergedEmployees" target="total_worth">
          <ul class="pl-15 m-0">
            <li v-for="employee in mergedEmployees">
              {{ employee.name }}: {{ Math.ceil((employee.seconds || 0) / 360) | formatNumber }}ч. *
              {{ employee.worth_per_hour | formatNumber }} &#8381; = {{ employee.worth | formatNumber }}
              &#8381;
            </li>
          </ul>
          <br />
          Итого {{ data.item.hours | formatNumber }} часов = {{ data.value || 0 | formatNumber }} &#8381;<br />
          Средняя себестоимость часа (факт) = {{ data.item.average_cost_fact_hours || 0 | formatNumber }} &#8381;
        </popover>
      </template>

      <template slot="margin" slot-scope="data">
        {{ data.value || 0 | formatNumber }} &#8381; ({{ data.item.margin_percent || 0 | formatNumber }}%)
      </template>

      <template slot="hours" slot-scope="data">
        <span id="total_hours"
          >{{ data.value || 0 | formatNumber }}ч / {{ data.item.plan_hours || 0 | formatNumber }}ч ({{
            data.item.hours_mastering_percent || 0 | formatNumber
          }}%)</span
        >
        <popover v-if="mergedEmployees" target="total_hours">
          <ul class="pl-15 m-0">
            <li v-for="employee in mergedEmployees">{{ employee.name }}: {{ employee.seconds | formatDuration }}</li>
          </ul>
          <br />
          Остаток времени по проектам: {{ data.item.hours_balance || 0 | formatNumber }}ч
        </popover>
      </template>

      <template slot="bonus" slot-scope="data">
        <span id="bonus_future" style="color: #ffbc0a"> {{ data.item.bonus_future | formatNumber }} &#8381; </span>

        <span style="color: #ccc; margin: 0 5px">|</span>

        <span id="bonus" style="color: #6ead1f"> {{ data.value || 0 | formatNumber }} &#8381; </span>

        <popover target="bonus_future">
          <div>
            <b>Не закрытые: {{ data.item.bonus_future | formatNumber }}&#8381;</b>
            <ul class="pl-15 m-0 mt-5">
              <li v-for="employee in data.item.project_managers_future">
                {{ employee.name }}: {{ employee.bonus | formatNumber }}&#8381;
              </li>
            </ul>
          </div>
        </popover>

        <popover target="bonus">
          <div>
            <b>Закрытые: {{ data.item.bonus | formatNumber }}&#8381;</b>
            <ul class="pl-15 m-0 mt-5">
              <li v-for="employee in data.item.project_managers">
                {{ employee.name }}: {{ employee.bonus | formatNumber }}&#8381;
              </li>
            </ul>
          </div>
        </popover>
      </template>

      <!--      <template slot="bonus_new_closed_total" slot-scope="data">
              <span id="total_bonus_new_open_total" style="color: #ffbc0a">
                {{ data.item.bonus_new_open_total | formatNumber }} ({{
                  data.item.bonus_new_open_total_percent | formatNumber
                }}%)
              </span>
              <span style="color: #ccc; margin: 0 5px">|</span>
              <span id="total_bonus_new_closed_total" style="color: #6ead1f">
                {{ data.value || 0 | formatNumber }} ({{ data.item.bonus_new_closed_total_percent | formatNumber }}%)
              </span>

              <popover target="total_bonus_new_open_total">
                <div>
                  <b>Не закрытые: {{ data.item.bonus_new_open_total | formatNumber }}&#8381;</b>
                  <ul class="pl-15 m-0 mt-5">
                    <li v-for="employee in data.item.bonus_new_open_employee_statistic">
                      {{ employee.name }}: {{ employee.hours | formatDuration(undefined, undefined, 'hours') }} *
                      {{ employee.premium | formatNumber }}&#8381; *
                      <span :class="{ red: employee.premium_coeff <= 1, green: employee.premium_coeff > 1 }">
                        {{ employee.premium_coeff | formatNumber('0,0.[00]') }}
                      </span>
                      = {{ employee.total | formatNumber }}&#8381;
                    </li>
                  </ul>
                </div>
              </popover>

              <popover target="total_bonus_new_closed_total">
                <div>
                  <b>Закрытые: {{ data.item.bonus_new_closed_total | formatNumber }}&#8381;</b>
                  <ul class="pl-15 m-0 mt-5">
                    <li v-for="employee in data.item.bonus_new_closed_employee_statistic">
                      {{ employee.name }}: {{ employee.hours | formatDuration(undefined, undefined, 'hours') }} *
                      {{ employee.premium | formatNumber }}&#8381; *
                      <span :class="{ red: employee.premium_coeff <= 1, green: employee.premium_coeff > 1 }">
                        {{ employee.premium_coeff | formatNumber('0,0.[00]') }}
                      </span>
                      = {{ employee.total | formatNumber }}&#8381;
                    </li>
                  </ul>
                </div>
              </popover>
            </template>-->

      <template slot="nps" slot-scope="data">
        {{ data.value | formatNumber }}
      </template>

      <!--Прямые расходы-->
      <template slot="direct_expenses_total" slot-scope="{ item, value }">
        <span id="direct_expenses_total"> {{ item['actual_direct_expenses_total'] | formatNumber }} &#8381; </span>
        <popover target="direct_expenses_total">
          <div>
            <b>Фактические:</b>
            {{ item['actual_direct_expenses_total'] | formatNumber }} &#8381;
          </div>
          <div>
            <b>Плановые:</b>
            {{ (item['direct_expenses_total'] - item['actual_direct_expenses_total']) | formatNumber }} &#8381;
          </div>
          <div>
            <b>Всего:</b>
            {{ value | formatNumber }} &#8381;
          </div>
        </popover>
      </template>

      <template slot="mo_total" slot-scope="{ item, value }"> {{ value | formatNumber }} &#8381; </template>
    </b-table>

    <div class="panel-loading">
      <div class="loader loader-default"></div>
    </div>
  </b-card>
</template>

<script>
  import api from '../../assets/js/api'
  import rightsMixins from '../../mixins/rights'

  import Popover from '../../components/PopoverAlive'

  import mergeWith from 'lodash/mergeWith'

  export default {
    name: 'Summary',
    components: {
      Popover,
    },
    props: ['filters', 'count'],
    mixins: [rightsMixins],
    data() {
      return {
        data: {},
        loading: true,
        table: {
          fields: [
            {
              key: 'count',
              label: 'Количество проектов',
            },
            {
              key: 'hours',
              label: 'Кол-во часов',
              class: 'text-center',
            },
            {
              key: 'budget',
              label: 'Бюджет',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'payments',
              label: 'Оплачено',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'duty',
              label: 'Неоплачено',
              class: 'text-center',
              roles: ['admin'],
            },
            // {
            //   key: 'free_budget',
            //   label: 'Остаток бюджета',
            //   class: 'text-center',
            //   roles: ['admin'],
            // },
            {
              key: 'direct_expenses_total',
              label: 'Прямые расходы',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'mo_total',
              label: 'Марж. отгрузка',
              class: 'text-center',
            },
            {
              key: 'worth',
              label: 'Себестоимость',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'margin',
              label: 'Валовая прибыль',
              class: 'text-center',
              roles: ['admin'],
            },
            {
              key: 'bonus',
              label: 'Премии',
              class: 'text-center',
            },
            {
              key: 'nps',
              label: 'NPS',
              class: 'text-center',
            },
          ],
        },
      }
    },
    computed: {
      mergedEmployees() {
        if (this.data) {
          return mergeWith({}, this.data.project_managers, this.data.employee_statistic, (objValue, srcValue, key) => {
            if (key === 'bonus') {
              return (objValue || 0) + (srcValue || 0)
            }
          })
        } else return null
      },
    },
    methods: {
      update() {
        this.loading = true

        api.base
          .get({
            requestId: 'projectsSummary',
            path: 'projects/summary',
            params: this.filters,
          })
          .then(result => {
            this.data = result
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },
    },
    created() {
      this.update()
      this.cleanRolesElements(this.table.fields)
    },
    watch: {
      $route() {
        this.update()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card {
    border-top: 1px solid #e0e0e0 !important;
    margin-bottom: 2rem !important;

    .loader {
      top: 30% !important;
    }

    .card-body {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    td,
    th {
      border: 0 !important;
    }
  }
</style>
